import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
          Transactology introduces a slightly different view of blockchain and other 
          technology which you may miss but you want.
          </p>
          {close}
        </article>

        <article
          id="works"
          className={`${this.props.article === 'works' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Works</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3 className="major">BBTS: Blockchain Benchmark Test Set</h3>
          <p>
            BBTS(Blockchain Benchmark Test Set) provides a more pragmatic, general and 
            standard test set for blockchain applications. <br />
            For example, the Smallbank test set gives a simple benchmark. <br />
            However, you can't test inter-chain performance or the effect of 
            inter-transaction dependency. <br />
            Therefore, we bring a new test set BBTS.
          </p>
          <p>
            This test set covers performance scenarios like inter-chain or inter-shard 
            case, inter-transaction case, temporal locality case, etc. <br />
            Also, BBTS bundles validator and analyzer which could check integrity of the 
            result.
          </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3 className="major">TPAL: Tx Proposal Aggregating Layer</h3>
          <p>
            TPAL is an add-on layer for batching Tx Proposals for better concurrency and 
            serialization.
          </p>
          <p>
            TPAL provides distributed TPA pre-processing network and Tx execution 
            improving plug-in.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Transactology aims to design a better transactional system that includes a 
            blockchain system.
          </p>
          <p>
            Furthermore, Transactology provides its substantial materials like benchmark 
            tool and test set.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/Transactology"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://holos.works" className="icon fa-paper-plane-o">
                <span className="label">Holos Works</span>
              </a>
            </li>
            <li>
              <a href="https://ppuzzl.com" className="icon fa-home">
                <span className="label">PPUZZL Group</span>
              </a>
            </li>
            <li>
              <a
                href="https://gitlab.com/transactology"
                className="icon fa-gitlab"
              >
                <span className="label">GitLab</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/transactology"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
